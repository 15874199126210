<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }"> Shop </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-row v-if="product" class="my-2">
      <!-- Left: Product Image Container -->
      <b-col cols="12" md="7">
        <!-- Product Name -->
        <b-card-text class="item-company">
          <b-link class="company-name">
            {{ product.brand }}
          </b-link>
        </b-card-text>
        <h4 class="mb-3">{{ product.name }}</h4>

        <!-- Avability -->
        <b-card-text>Tentang buku ini </b-card-text>

        <!-- Product Description -->
        <b-card-text>{{ product.description }}</b-card-text>

        <!-- <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0" @click="handleCartActionClick(product)">
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{ product.isInCart ? "View In Cart" : "Add to Cart" }}</span>
              </b-button>
              <b-button variant="outline-secondary" class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0" @click="toggleProductInWishlist(product)">
                <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': product.isInWishlist }" />
                <span>Wishlist</span>
              </b-button>
              <b-dropdown variant="outline-secondary" no-caret toggle-class="btn-icon" class="btn-share" right>
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item v-for="icon in ['FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon']" :key="icon">
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div> -->
      </b-col>

      <!-- Right: Product Details -->

      <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
        <b-card>
          <b-card-body>
            <b-img :src="product.image" :alt="`Image of ${product.name}`" class="product-img" fluid />
            Harga <br />
            <h3 class="text-primary mt-1">Rp{{ product.price }}</h3>
            <feather-icon icon="CalendarIcon" class="mr-50" />
            <small class="font-weight-bold">Terbit 2023</small>
            <feather-icon icon="BookOpenIcon" class="mr-50 ml-2" />
            <small class="font-weight-bold">198 halaman</small>
          </b-card-body>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart btn-block" @click="handleCartActionClick(product)">
            <span>Beli</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import { BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ECommerceProductDetailsItemFeatures from "./ECommerceProductDetailsItemFeatures.vue";
import ECommerceProductDetailsRelatedProducts from "./ECommerceProductDetailsRelatedProducts.vue";
import { useEcommerceUi } from "../useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const product = ref(null);

    // Remote Data
    const fetchProduct = () => {
      // Get product  id from URL
      const { route } = useRouter();
      const productSlug = route.value.params.slug;
      const productId = productSlug.substring(productSlug.lastIndexOf("-") + 1);

      store
        .dispatch("app-ecommerce/fetchProduct", { productId })
        .then((response) => {
          product.value = response.data.product;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            product.value = undefined;
          }
        });
    };

    // UI
    const selectedColor = ref(null);

    fetchProduct();

    return {
      // Fetched Product
      product,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
